import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App, { APP_ROOT_ID } from './App';
import reportWebVitals from './reportWebVitals';
import packageJson from '../package.json';

// import i18n (needs to be bundled ;))
import './i18n';

// Sentry: Disabled on LOCALDEV, Enabled everywhere else, debug mode for QA environment only.
const enableSentry = window.cp_constants.ENV !== 'localdev';
if (enableSentry) {
  Sentry.init({
    dsn: 'https://6edaff888f3c49c7970850bb1ed42cda@o1261169.ingest.sentry.io/4503938153447424',
    debug: window.cp_constants.ENV === 'qa',
    denyUrls: ['http://localhost:20099/'],
    environment: window.cp_constants.ENV,
    integrations: [new BrowserTracing()],
    release: `${packageJson.name} v${packageJson.version}`,
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    tracesSampleRate: 0.05, // 5% of transactions
  });
}

const root = createRoot(document.getElementById(APP_ROOT_ID));

root.render(<React.StrictMode><App /></React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
