import { KitUtilCommon } from '@chargepoint/cp-toolkit';

const LOCALE_CODE_KEY = 'sso_locale_code';

const getLocaleCode = (defaultLocale) => {
  if (!KitUtilCommon.localStorageAvailable()) {
    return defaultLocale;
  }
  return window.localStorage.getItem(LOCALE_CODE_KEY) || defaultLocale;
};

const setLocaleCode = (localeCode) => {
  if (KitUtilCommon.localStorageAvailable()) {
    window.localStorage.setItem(LOCALE_CODE_KEY, localeCode);
  }
};

export default {
  getLocaleCode,
  setLocaleCode,
};
