import React, { lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';
import FallbackLoader from './FallbackLoader';

const SignIn = lazy(() => import('../pages/SignIn'));
const Logout = lazy(() => import('../pages/Logout'));
const ForgotUsername = lazy(() => import('../pages/ForgotUsername'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));
const SelectRole = lazy(() => import('../pages/SelectRole'));
const MFACodeScan = lazy(() => import('../pages/MFACodeScan'));
const MFAEnterCode = lazy(() => import('../pages/MFAEnterCode'));

function Routes() {
  return (
    <Suspense fallback={<FallbackLoader />}>
      <Route exact path='/' component={SignIn} />
      <Route exact path='/logout' component={Logout} />
      <Route exact path='/forgot-username' component={ForgotUsername} />
      <Route exact path='/forgot-password' component={ForgotPassword} />
      <Route exact path='/reset-password/:token' component={ResetPassword} />
      <Route exact path='/select-role' component={SelectRole} />
      <Route exact path='/qrcode-scan' component={MFACodeScan} />
      <Route exact path='/enter-code' component={MFAEnterCode} />
    </Suspense>
  );
}

export default Routes;
