/* eslint-disable no-unused-vars */
import { Suspense, useEffect } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import {
  cpLightTheme,
  KitFontUrl,
  KitGlobalStyles,
  KitSpinner,
  ThemeConstants,
  KitHelmet,
  HelmetProvider,
  KitAppLayout
} from '@chargepoint/cp-toolkit';
import Routes from './components/Routes';
import FeatureFlagService from './services/FeatureFlagService';

export const APP_ROOT_ID = 'react-chargepoint-sso-root';

// Simple global override to make our div and p elements match font size.
export const SsoGlobalStyleOverrides = createGlobalStyle`
  body {
    div {
      font-size: ${ThemeConstants.fontSize.p}rem;
    }
  }
`;

function App() {
  const helmetContext = {};

  useEffect(() => {
    (async () => {
      console.info('calling initFeatureFlags...');
      await FeatureFlagService.getInstance().initFeatureFlags();
    })();
  }, []);

  return (
    <ThemeProvider theme={cpLightTheme}>
      <HelmetProvider context={helmetContext}>
        <KitHelmet locale='en-US'>
          <link href={KitFontUrl} rel='stylesheet' />
        </KitHelmet>
        <KitGlobalStyles rootId={APP_ROOT_ID} />
        <SsoGlobalStyleOverrides />
        <Suspense fallback={<KitSpinner />}>
          <CookiesProvider>
            <BrowserRouter>
              <KitAppLayout>
                <Switch>
                  <Routes />
                </Switch>
              </KitAppLayout>
            </BrowserRouter>
          </CookiesProvider>
        </Suspense>
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
