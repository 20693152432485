import * as Rox from 'rox-browser';

class FeatureFlagService {
  static instance;

  static initialized;

  roxFlags = {
    signupSubdomainEnabled: new Rox.Flag(false),
  };

  static getInstance() {
    if (!FeatureFlagService.instance) {
      FeatureFlagService.instance = new FeatureFlagService();
    }
    return FeatureFlagService.instance;
  }

  async initFeatureFlags() {
    if (!FeatureFlagService.initialized) {
      FeatureFlagService.initialized = true;
      const options = {};
      Rox.register('', this.roxFlags);
      await Rox.setup(window.cp_constants.CLOUDBEES_API_KEY, options);
    }
  }

  isEnabled(featureFlag) {
    const { hasOwnProperty } = Object.prototype;
    if (hasOwnProperty.call(this.roxFlags, featureFlag)) {
      return this.roxFlags[featureFlag].isEnabled();
    }
    return false;
  }
}

FeatureFlagService.signupSubdomainEnabled = 'signupSubdomainEnabled';

export default FeatureFlagService;
