import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CPLogo, KitFlexColCentered, KitSpinner, ThemeConstants } from '@chargepoint/cp-toolkit';

const KitSpinnerWrapper = styled(KitFlexColCentered)`
  align-items: center;
  height: 100%;
`;

const Logo = styled(CPLogo)`
  width: 200px;
  margin-bottom: ${ThemeConstants.spacing.absolute.m}px;
`;

function FallbackLoader() {
  const { t } = useTranslation();

  return (
    <KitSpinnerWrapper>
      <div>
        <Logo t={t} />
        <KitSpinner />
      </div>
    </KitSpinnerWrapper>
  );
}

export default FallbackLoader;
